// Words are gradually being added!

export const VALID_GUESSES = [
  "abren",
  "acaso",
  "acata",
  "acato",
  "acera",
  "acero",
  "acoja",
  "acojo",
  "acres",
  "actas",
  "adobe",
  "aduce",
  "agote",
  "aguda",
  "aires",
  "ajuar",
  "alces",
  "alcor",
  "alias",
  "aloja",
  "altos",
  "amaga",
  "amena",
  "anexo",
  "apela",
  "apnea",
  "apoca",
  "apodo",
  "apuro",
  "arana",
  "arces",
  "arcos",
  "ardor",
  "arduo",
  "arena",
  "arepa",
  "arpas",
  "arras",
  "arreo",
  "asilo",
  "asomo",
  "astil",
  "ataco",
  "atado",
  "atajo",
  "aupar",
  "aviar",
  "azota",
  "azote",
  "babel",
  "bajel",
  "banal",
  "batas",
  "batel",
  "bates",
  "bayas",
  "beato",
  "bedel",
  "besos",
  "birla",
  "bodas",
  "bonos",
  "boyas",
  "bozal",
  "brida",
  "broca",
  "burla",
  "busca",
  "cabal",
  "cajas",
  "calar",
  "calma",
  "canal",
  "canas",
  "canon",
  "cansa",
  "cariz",
  "casal",
  "casar",
  "casco",
  "casta",
  "catan",
  "catar",
  "catre",
  "cauto",
  "cejas",
  "celar",
  "cerda",
  "cerdo",
  "cesto",
  "chapa",
  "chile",
  "china",
  "cieno",
  "cirro",
  "clase",
  "clima",
  "cloro",
  "colas",
  "colmo",
  "copar",
  "copas",
  "copos",
  "coral",
  "corea",
  "corre",
  "corza",
  "craso",
  "creer",
  "crema",
  "criba",
  "cromo",
  "cruje",
  "cubil",
  "cuida",
  "cunas",
  "dalia",
  "datar",
  "datos",
  "deben",
  "debes",
  "densa",
  "diosa",
  "docto",
  "doler",
  "domar",
  "donar",
  "dotes",
  "draga",
  "drena",
  "echar",
  "edita",
  "educa",
  "ejote",
  "eleva",
  "elige",
  "elote",
  "eluda",
  "email",
  "enana",
  "emite",
  "enano",
  "enema",
  "enojo",
  "entra",
  "entre",
  "entro",
  "equis",
  "erizo",
  "eroga",
  "errar",
  "error",
  "esmog",
  "esnob",
  "estar",
  "estoy",
  "estro",
  "etapa",
  "etnia",
  "evade",
  "evita",
  "evoca",
  "exige",
  "exime",
  "extra",
  "faena",
  "fajar",
  "falaz",
  "falda",
  "fallo",
  "falso",
  "fango",
  "fardo",
  "farol",
  "faros",
  "farsa",
  "fases",
  "fasto",
  "fatal",
  "fauna",
  "favor",
  "fecha",
  "feliz",
  "feria",
  "feroz",
  "fibra",
  "ficha",
  "fijar",
  "final",
  "finca",
  "firma",
  "fisga",
  "flaco",
  "fleco",
  "flojo",
  "flota",
  "flujo",
  "fobia",
  "folio",
  "fondo",
  "forma",
  "forro",
  "frase",
  "freno",
  "fruto",
  "fuego",
  "fuera",
  "fugar",
  "funda",
  "furor",
  "fusta",
  "gafar",
  "gafas",
  "gaita",
  "galas",
  "gallo",
  "ganar",
  "ganas",
  "ganga",
  "ganso",
  "garra",
  "garza",
  "gasas",
  "gasto",
  "gemas",
  "gemir",
  "genio",
  "gente",
  "gesto",
  "girar",
  "globo",
  "gnomo",
  "gofre",
  "goles",
  "golfo",
  "golpe",
  "gordo",
  "gorra",
  "gorro",
  "gotas",
  "gozar",
  "grada",
  "grado",
  "gramo",
  "grano",
  "grasa",
  "grato",
  "grava",
  "grave",
  "grifo",
  "grito",
  "grupo",
  "guapo",
  "guiar",
  "guiso",
  "gusto",
  "haber",
  "habla",
  "hacer",
  "hacha",
  "hacia",
  "hadas",
  "halar",
  "halla",
  "harto",
  "hasta",
  "hecho",
  "heder",
  "helar",
  "herir",
  "hielo",
  "himno",
  "hipar",
  "hobby",
  "hogar",
  "hondo",
  "hongo",
  "horda",
  "hotel",
  "hucha",
  "hueco",
  "huele",
  "hueso",
  "huevo",
  "huida",
  "humor",
  "hunde",
  "hurta",
  "hurto"
];
